
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    background-color: #f7f5f3;
}
.TipsPage{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:#33343D;
}
.TipsPage .arrowing{
  position:absolute;
  top: 20px;
  right:10px;
  width: 129px;
  height: 177px;
}
.logoBox{
  display: flex;
  justify-content: center;
  margin-top: 60px;
  width: 100%;
  height: 80px;
}
.logoBox img{
  width:80px;
  height:80px;
}
.hreadBox p{
  font-size: 20px;
  font-family: PingFang SC;
  color: #04040f;
  margin: 10px 0;
  text-align: center;
}
.conterBox{
  width: 80%;
  font-size: 18px;
  margin: 30px auto;
  color: #FFE75E ;
  border: 2px solid #FFE75E ;
}
.conterBox p {
  text-align: center;
}



div[data-v-50a62d1e] .van-nav-bar {
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
div[data-v-50a62d1e] .van-nav-bar__content {
  flex: 1;
}
div[data-v-50a62d1e] .van-nav-bar__right {
  font-size: 1.5rem;
}
div[data-v-50a62d1e].van-grid-item {
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}
div[data-v-50a62d1e] .van-grid-item__content {
  padding: 0.8rem;
  border-radius: 0.5rem;
}
div[data-v-50a62d1e] .van-grid-item__content--center {
  align-items: stretch;
  -webkit-align-items: stretch;
}
.headTitle[data-v-50a62d1e] {
  text-align: center;
  line-height: 1.5rem;
  color: #252A33;
}
.headTitle h1[data-v-50a62d1e] {
  margin: 0;
}
.headTitle span[data-v-50a62d1e] {
  color: #7D8799;
  font-weight: bold;
  font-size: 1rem;
  font-family: PingFang SC;
}
.headTitle h1[data-v-50a62d1e] {
  font-size: 1.3rem;
}
.popupTitle[data-v-50a62d1e] {
  height: 2.5rem;
  background: #F4F4F4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.popupTitle .title[data-v-50a62d1e] {
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
}
.popupTitle .btnBox[data-v-50a62d1e] {
  width: 3rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}
.tabContent[data-v-50a62d1e] {
  margin-top: 1rem;
}
.tabContent .title[data-v-50a62d1e] {
  margin: 1.5rem 1rem 0 1rem;
  color: #7D8799;
  font-weight: 400;
  font-family: PingFang SC;
}
.tabContent .itemTitle[data-v-50a62d1e] {
  height: 2.625rem;
  color: #252A33;
}
.tabContent .instructions .title[data-v-50a62d1e] {
  margin: 1rem;
  color: #7D8799;
  font-weight: 400;
  font-family: PingFang SC;
}
.tabContent .foot[data-v-50a62d1e] {
  margin-top: 0.5rem;
  height: 40px;
  text-align: right;
  font-size: 2.5rem;
}
.tabContent .videoBox[data-v-50a62d1e] {
  border-radius: 0.5rem;
  padding: 1rem;
  background: #fff;
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #252A33;
  font-weight: 400;
}
.tabContent .tip[data-v-50a62d1e] {
  color: #7D8799;
  text-align: center;
  margin: 1rem 0;
  font-size: 1rem;
}
div[data-v-50a62d1e] .faqTitle {
  font-weight: bold;
  color: #252A33;
}
.videoBox[data-v-50a62d1e] {
  margin-top: 1rem;
}
.btnBox[data-v-50a62d1e] {
  text-align: right;
  margin: 1rem;
}
.loadErrorBox[data-v-50a62d1e] {
  text-align: center;
  height: calc(100vh - 8.75rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}
.loadErrorBox .networkImg[data-v-50a62d1e] {
  width: 10rem;
  height: 10rem;
}
.loadErrorBox .tipBox[data-v-50a62d1e] {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loadErrorBox .tipBox span[data-v-50a62d1e] {
  color: #7D8799;
  font-weight: 400;
  margin: 0 0.5rem;
}
.loadErrorBox .tipBox img[data-v-50a62d1e] {
  width: 1rem;
}


.loadBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(37, 42, 51, 0.8);;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  font-weight: 400;
  padding: 5px;
}

